import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import debounce from 'lodash/debounce';

import ReactPlayer, { HTML5Player } from './ReactPlayer';

import useMoveToNextBiteSection from '../../hooks/useMoveToNextBiteSection';
import useVimeoLink from '../../hooks/useVimeoLinks';
import { GtmVideo } from '../../services/googleTagManager';
import useGetVideoPlayerSize from '../../hooks/useGetVideoPlayerSize';
import { isSafari } from 'react-device-detect';

interface IProps {
  media_url: string | null;
  videoStartsAt: number | null;
  videoEndsAt: number | null;
  file_type: 'video' | 'youtube' | 'image' | 'google_slides';
  closeModal: any;
  onFullyWatched?: () => void;
  onVideoMetaDataLoaded?: () => void;
  onPlay?: () => void;
  stayOnCurrentSection?: boolean;
  playerRef: any;
  section?: string | null;
  playing?: boolean | true;
  controls?: boolean | false;
  muted?: boolean | true;
  loop?: boolean | false;
  isFullScreen?: boolean;
  backgroundImage?: string | null;
  subtitles_url?: string | null;
  subtitles_locale?: string | 'en';
  is_cc_enabled?: boolean;
  isSkipable?: boolean;
  isVideoWatchCompleted?: boolean;
}

interface IYoutubeConfig {
  playerVars: {
    cc_load_policy?: 1 | 0;
  };
}

const VideoPlayer: FC<IProps> = ({
  onFullyWatched,
  closeModal,
  stayOnCurrentSection,
  playerRef,
  section,
  playing,
  controls,
  muted,
  loop,
  subtitles_url,
  subtitles_locale,
  isFullScreen,
  backgroundImage,
  is_cc_enabled,
  isSkipable,
  isVideoWatchCompleted,
  onVideoMetaDataLoaded,
  onPlay,
  ...mediaProps
}) => {
  const { getVideoPlayerSize } = useGetVideoPlayerSize();
  const [playerSize, setPlayerSize] = useState(null);

  const { proceedToNextSectionWithDelay } = useMoveToNextBiteSection({
    sectionType: section || 'intro',
  });

  const isVimeoLinkRequired = mediaProps.file_type === 'video';
  const { fetchingLinkError, isFetchingLink, vimeoVideoUri, isVideoStillDecoding, size } = useVimeoLink(
    isVimeoLinkRequired,
    mediaProps.media_url,
  );

  const youtubeLink =
    mediaProps.file_type === 'youtube'
      ? mediaProps.media_url + '&start=' + mediaProps.videoStartsAt + '&end=' + mediaProps.videoEndsAt
      : null;

  const currentTime = useRef(0);
  const isSeeking = useRef(false);

  const onProgress = ({ playedSeconds }) => {
    if (!isSeeking.current) {
      currentTime.current = playedSeconds;
    }
  };

  const debouncedSenVolumeData = useRef(debounce(() => sendVolumeChangeData(), 1000)).current;

  useEffect(() => {
    if (!playerRef.current || !isVimeoLinkRequired || fetchingLinkError || isVideoStillDecoding || isFetchingLink) {
      return;
    }

    const internalPlayer: HTMLVideoElement = playerRef.current.getInternalPlayer();
    const callback = () => {
      debouncedSenVolumeData();
    };

    internalPlayer?.addEventListener('volumechange', callback);

    return () => {
      internalPlayer?.removeEventListener('volumechange', callback);
    };
  }, [debouncedSenVolumeData, fetchingLinkError, isFetchingLink, isVideoStillDecoding, isVimeoLinkRequired, playerRef]);

  // handle blcking of seek for non skipable videos
  useEffect(() => {
    if (
      isSkipable ||
      isVideoWatchCompleted ||
      !playerRef.current ||
      !isVimeoLinkRequired ||
      fetchingLinkError ||
      isVideoStillDecoding ||
      isFetchingLink
    ) {
      return;
    }

    let supposedCurrentTime = 0;
    let maxWatchedTime = 0;

    const internalPlayer: HTMLVideoElement = playerRef.current.getInternalPlayer();

    const timeUpdateCallback = () => {
      if (!internalPlayer.seeking) {
        supposedCurrentTime = internalPlayer.currentTime;
        maxWatchedTime = Math.max(supposedCurrentTime, maxWatchedTime);
      }
    };
    const seekingCallback = () => {
      const delta = internalPlayer.currentTime - maxWatchedTime;
      if (delta > 0.01) {
        internalPlayer.currentTime = supposedCurrentTime;
      }
    };
    const endedCallback = () => {
      supposedCurrentTime = 0;
    };

    internalPlayer?.addEventListener('timeupdate', timeUpdateCallback);
    internalPlayer?.addEventListener('seeking', seekingCallback);
    internalPlayer?.addEventListener('ended', endedCallback);

    return () => {
      internalPlayer?.removeEventListener('timeupdate', timeUpdateCallback);
      internalPlayer?.removeEventListener('seeking', seekingCallback);
      internalPlayer?.removeEventListener('ended', endedCallback);
    };
  }, [
    fetchingLinkError,
    isFetchingLink,
    isSkipable,
    isVideoStillDecoding,
    isVideoWatchCompleted,
    isVimeoLinkRequired,
    playerRef,
  ]);

  const handleVideoEnded = () => {
    const tagManagerVideo = new GtmVideo(
      'Video Ended',
      playerRef.current?.getCurrentTime(),
      playerRef.current?.getDuration(),
      vimeoVideoUri,
      isVimeoLinkRequired ? 'Vimeo' : 'Other',
    );

    tagManagerVideo.sendData();

    closeModal();
    if (!stayOnCurrentSection) {
      proceedToNextSectionWithDelay(true);
    }
    onFullyWatched?.();
  };

  const debouncedSendSeekData = useRef(debounce((nextValue: number) => sendOnSeekData(nextValue), 1000)).current;

  const sendOnSeekData = (seconds: number) => {
    if (playerRef.current) {
      const tagManagerVideo = new GtmVideo(
        'Video Seek',
        seconds - 1,
        playerRef.current.getDuration(),
        vimeoVideoUri,
        isVimeoLinkRequired ? 'Vimeo' : 'Other',
        'gtm.seekFrom',
        currentTime.current,
      );

      tagManagerVideo.sendData();
    }
    isSeeking.current = false;
  };

  const sendVolumeChangeData = () => {
    if (!playerRef.current) {
      return;
    }
    const internalPlayer = playerRef.current?.getInternalPlayer();
    const volume = internalPlayer?.muted ? 'Muted' : `${(internalPlayer?.volume * 100).toFixed(2)}%`;

    const tagManagerVideo = new GtmVideo(
      'Video Volume Changed',
      playerRef.current?.getCurrentTime(),
      playerRef.current?.getDuration(),
      vimeoVideoUri,
      isVimeoLinkRequired ? 'Vimeo' : 'Other',
      'gtm.videoVolume',
      volume,
    );

    tagManagerVideo.sendData();
  };

  const onSeek = (seconds: number) => {
    isSeeking.current = true;
    debouncedSendSeekData(seconds);
  };

  const handlePlay = () => {
    const timer = setTimeout(() => {
      if (!isSeeking.current && playerRef.current) {
        const tagManagerVideo = new GtmVideo(
          'Video Played',
          playerRef.current.getCurrentTime() - 0.2,
          playerRef.current.getDuration(),
          vimeoVideoUri,
          isVimeoLinkRequired ? 'Vimeo' : 'Other',
        );

        tagManagerVideo.sendData();
      }
      clearTimeout(timer);
    }, 200);
    if (typeof onPlay === 'function') {
      onPlay();
    }
  };

  const onPause = () => {
    const timer = setTimeout(() => {
      if (!isSeeking.current && playerRef.current) {
        const tagManagerVideo = new GtmVideo(
          'Video Paused',
          playerRef.current.getCurrentTime() - 0.2,
          playerRef.current.getDuration(),
          vimeoVideoUri,
          isVimeoLinkRequired ? 'Vimeo' : 'Other',
        );

        tagManagerVideo.sendData();
      }
      clearTimeout(timer);
    }, 200);
  };

  const videoWidth = size ? size.width : 'auto';
  const videoHeight = size ? size.height : 'auto';

  const isPortraitVideo = videoWidth < videoHeight;

  useEffect(() => {
    setPlayerSize(getVideoPlayerSize(isFullScreen, videoWidth, videoHeight, mediaProps?.file_type === 'youtube'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFullScreen, videoWidth, videoHeight, mediaProps?.file_type]);

  const videoUrl = isVimeoLinkRequired
    ? vimeoVideoUri
    : mediaProps.file_type === 'youtube'
    ? youtubeLink
    : mediaProps.media_url;

  const Player = useMemo(() => {
    return isVimeoLinkRequired ? HTML5Player : ReactPlayer;
  }, [isVimeoLinkRequired]);

  const config = useMemo(() => {
    const viderUrlHostname = videoUrl && new URL(videoUrl).hostname;

    const isBitesStreaming =
      videoUrl && ['video.small-bites.com', 'video.mybiteshares.com', 'video.mybites.io'].includes(viderUrlHostname);

    const isVimeoUrl = videoUrl && ['player.vimeo.com'].includes(viderUrlHostname);

    const fileConfig = {
      attributes: {
        crossOrigin: isBitesStreaming ? 'use-credentials' : isVimeoUrl && isSafari ? undefined : 'anonymous',
        controlsList: 'nodownload noplaybackrate',
        disablePictureInPicture: true,
        onContextMenu: (e) => e.preventDefault(),
      },
      hlsVersion: '1.5.13',
      hlsOptions: {
        xhrSetup: (xhr) => {
          if (isBitesStreaming) {
            xhr.withCredentials = true;
          }
        },
      },
      tracks:
        subtitles_url && isFullScreen
          ? [
              {
                kind: 'subtitles',
                label: '',
                src: `${subtitles_url}`,
                srcLang: `${subtitles_locale || 'en'}`,
                default: true,
              },
            ]
          : [],
    };

    const youtubeConfig: IYoutubeConfig = {
      playerVars: {
        cc_load_policy: is_cc_enabled ? 1 : 0,
      },
    };

    return isVimeoLinkRequired
      ? fileConfig
      : {
          file: fileConfig,
          youtube: youtubeConfig,
        };
  }, [videoUrl, subtitles_url, isFullScreen, subtitles_locale, is_cc_enabled, isVimeoLinkRequired]);

  useEffect(() => {
    if (!playerRef.current || !isVimeoLinkRequired || fetchingLinkError || isVideoStillDecoding || isFetchingLink) {
      return;
    }

    const internalPlayer = playerRef.current.getInternalPlayer();

    if (!internalPlayer) {
      return;
    }

    const callback = () => {
      const tracks = internalPlayer.textTracks;
      if (tracks.length > 0) {
        tracks[0].mode = 'showing'; // turn on subtitles
      }
    };

    // check the current player state in case loadedmetadata already fired
    if (internalPlayer.readyState >= 1) {
      callback();
      return;
    }

    internalPlayer.addEventListener('loadedmetadata', callback);

    return () => {
      internalPlayer?.removeEventListener('loadedmetadata', callback);
    };
  }, [
    fetchingLinkError,
    isFetchingLink,
    isVideoStillDecoding,
    isVimeoLinkRequired,
    playerRef,
    config, // include in dependencies, so that hls is updated with the new subtitles
  ]);

  const render = (renderPlayerSize) => {
    if (isVimeoLinkRequired && isFetchingLink) {
      return null; // vimeo links are fetched
    } else if (isVimeoLinkRequired && isVideoStillDecoding) {
      return <S.Message>Video is still decoding</S.Message>;
    } else if (isVimeoLinkRequired && fetchingLinkError) {
      return <S.Message>Error occurred while playing the video </S.Message>;
    } else {
      return (
        <S.Container playerSize={renderPlayerSize} isPortrait={isPortraitVideo} isFullScreen={isFullScreen}>
          <Player
            ref={playerRef}
            url={videoUrl}
            className='react-player'
            playing={playing}
            muted={muted}
            playsinline={true}
            controls={controls}
            loop={loop}
            width='100%'
            height='100%'
            onEnded={handleVideoEnded}
            onReady={onVideoMetaDataLoaded}
            onPlay={handlePlay}
            onPause={onPause}
            onProgress={onProgress}
            onSeek={onSeek}
            style={{
              display: 'block',
              flexDirection: 'column',
              justifyContent: 'center',
              background: backgroundImage ? `url(${backgroundImage}) no-repeat cover` : 'null',
            }}
            config={config}
            key={subtitles_url}
          />
        </S.Container>
      );
    }
  };

  return render(playerSize);
};

const S = {
  Container: styled.div<{ playerSize; isPortrait; isFullScreen }>`
    video,
    iframe {
      ${({ playerSize, isPortrait, isFullScreen }) => {
        return css`
          height: ${playerSize?.height || 'auto !important'};
          width: ${playerSize?.width || 'auto !important'};
          ${!isPortrait && !isFullScreen && 'max-height: 550px'};
          ${!isFullScreen &&
          `position: absolute;
          top: 50%;
          left: 49.9%;
          transform: translate(-50%, -50%);
          cursor: pointer;`}
        `;
      }}
    }
    width: 100%;
    height: 100%;
  `,
  Message: styled.div`
    color: ${({ theme }) => theme.colors.white};
  `,
};

export default VideoPlayer;
